const BookmarkIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      className={className}
    >
      <path
        d="M22.1673 25L14.0007 20.3333L5.83398 25V6.33333C5.83398 5.71449 6.07982 5.121 6.5174 4.68342C6.95499 4.24583 7.54848 4 8.16732 4H19.834C20.4528 4 21.0463 4.24583 21.4839 4.68342C21.9215 5.121 22.1673 5.71449 22.1673 6.33333V25Z"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookmarkIcon;
