import { productionReport } from "../../data/Home";
import Button from "../Common/Button";

interface ProductionReportSectionProps {
  reportName: string;
}

const ProductionReportSection = ({
  reportName,
}: ProductionReportSectionProps) => {
  return (
    <div className="flex flex-col items-start gap-[15px] mt-m items-center">
      <div className="flex justify-between	 items-center gap-[10px] self-stretch mt-[6px]">
        <h2 className="text-primaryText text-lg not-italic font-bold leading-[120%]">
          {reportName}
        </h2>
      </div>

      <div className="flex flex-col w-full bg-custom-deepCharcoal rounded-xs">
        <div className="flex w-full items-center">
          <div className="flex-[4] p-[8px] text-center font-primary text-sm not-italic font-semibold leading-[120%]">
            Header
          </div>
          <div className="flex-[2] p-[8px] font-primary text-center text-sm not-italic font-semibold leading-[120%]">
            2024 EFP
          </div>
          <div className="flex-[2] p-[8px] font-primary text-center text-sm not-italic font-semibold leading-[120%]">
            2023 EFP
          </div>
          <div className="flex-[2] p-[8px] font-primary text-sm not-italic font-semibold leading-[120%]">
            EFP GROWTH
          </div>
        </div>

        <div className="flex w-full ">
          <div className="flex-[4] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
            Total
          </div>
          <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
            164,956
          </div>
          <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
            165,023
          </div>
          <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
            0%
          </div>
        </div>

        {productionReport.map((report, index) => {
          return (
            <div
              className={`flex w-full items-center ${
                index % 2 === 0 ? "bg-custom-darkTeal" : ""
              }`}
              key={index}
            >
              <div className="flex-[4] p-[8px] text-primaryText text-center font-primary text-xs not-italic font-medium leading-[120%]">
                <div className="flex items-center gap-sm">
                  <img
                    src={report.avatar}
                    alt=""
                    className="w-[30px] h-[30px] border-[1px] border-solid border-white rounded-xl"
                  />
                  {report.name}
                </div>
              </div>
              <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
                {report.efp2024}
              </div>
              <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
                {report.efp2023}
              </div>
              <div className="flex-[2] p-[8px] text-primaryText text-center font-primary text-sm not-italic font-medium leading-[120%]">
                {report.efpGrowth}%
              </div>
            </div>
          );
        })}
      </div>

      <Button activeColor="active:bg-primary-400">
        <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
          View Full Report
        </span>
      </Button>
    </div>
  );
};

export default ProductionReportSection;
