import IconButton from "../Common/IconButton";

import chatLogoIcon from "../../assets/icons/ChatLogo.png";
import closeIcon from "../../assets/icons/Close.svg";

const ChatVVNTHeader = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex pt-[16px] pb-[26px] px-[18px] border-b-[0.5px] items-center border-solid border-custom-white-30">
      <div className="w-[40px] h-[40px] py-[12px] pl-[12px] pr-[7.5px] rounded-[50%] bg-base-white">
        <img src={chatLogoIcon} alt="Chat Icon" className=""></img>
      </div>

      <div className="flex flex-grow-[1] flex-col gap-[3px] ml-[12px]">
        <h2 className="text-white font-primary text-base not-italic font-semibold leading-[120%]">
          CHAT VVNT
        </h2>
        <h3 className="text-neutral-100 font-primary text-xs not-italic font-medium leading-[150%]">
          Our brand new chat agent, ask anything!
        </h3>
      </div>

      <IconButton icon={closeIcon} size={16} onClick={onClose}></IconButton>
    </div>
  );
};

export default ChatVVNTHeader;
