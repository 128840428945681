import { trendings } from "../../data/Home";

import salesIcon from "../../assets/icons/Sales.svg";

const TrendingSection = () => {
  return (
    <div className="flex flex-col items-start gap-[12px] mt-m">
      <div className="flex justify-between	 items-center gap-[10px] self-stretch">
        <h2 className="text-primaryText text-lg not-italic font-bold leading-[120%]">
          Trending
        </h2>
      </div>

      <div className="flex flex-col w-full gap-[15px]">
        {trendings.map((trending, index) => {
          return (
            <div
              className="flex items-center gap-[16px] px-sm py-[16px] bg-neutral-1000 rounded-xs w-full"
              key={index}
            >
              <div className="relative">
                <div className="w-[80px] h-80px border-[1px] rounded-xl border-solid border-neutral-700">
                  <img
                    src={trending.avatar}
                    alt="Avatar"
                    className="w-[80px] h-80px border-[2px] rounded-xl border-solid border-white"
                  />
                </div>

                <img
                  src={salesIcon}
                  alt="Sales"
                  className="absolute right-0 bottom-0 w-[24px] h-[24px] p-xs bg-primary-500 rounded-xl [box-shadow:0px_3.6px_16.8px_0px_rgba(0,_0,_0,_0.14)]"
                />
              </div>
              <div className="flex flex-col gap-xs">
                <p className="text-neutral-400 font-primary text-xs not-italic font-semibold leading-[120%]">
                  {trending.label}
                </p>

                <p className="text-base-white -font-primary text-base not-italic font-bold leading-[120%]">
                  {trending.title}
                </p>

                <p className="text-neutral-100 h-[34px] self-stretch text-base-white font-primary text-sm not-italic font-medium leading-[120%]">
                  {trending.info}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TrendingSection;
