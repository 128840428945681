import { useEffect, useRef, useState } from "react";
import { parseReportFilterName } from "../../utils/report";
import ArrowDown from "../icons/ArrowDown";
import Button from "./Button";

interface DropDownProps {
  options: string[]; // List of options to display in the dropdown
  selectedOption?: string[]; // Currently selected option
  onOptionSelect: (option: string[]) => void; // Callback when an option is selected
  label?: string; // Default label when no option is selected
  className?: string; // Custom class name
  buttonTextColor?: string; // Text color for the button
  buttonBgColor?: string; // Background color for the button
  buttonBorderColor?: string; // Border color for the button
  buttonWidth?: string; // Custom width for the button
  buttonHeight?: string; // Custom height for the button
  dropdownBg?: string;
  dropdownText?: string;
  dropdownOffset?: string; // Custom offset for dropdown
  buttonTextSize?: string;
  handleApply: (selectedOptions: string[]) => void;
}

const FilterDropDown: React.FC<DropDownProps> = ({
  options,
  selectedOption = [],
  onOptionSelect,
  label = "ALL",
  className = "",
  buttonTextColor = "text-base-white", // Default text color
  buttonBgColor = "bg-primary", // Default background color
  buttonBorderColor = "border-none", // Default no border
  buttonWidth = "w-[124px]", // Default width
  buttonHeight = "h-[28px]", // Default height
  dropdownBg = "bg-custom-dropdownBg",
  dropdownText = "bg-custom-dropdownText",
  dropdownOffset = "top-[32px]",
  buttonTextSize = "text-sm",
  handleApply,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState("left-0");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [filteredOptions, setFilteredOptions] = useState(options); // State for filtered options
  const [cacheSelectedOptions, setCacheSelectedOptions] =
    useState(selectedOption);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Handle option click
  const handleOptionClick = (option: string) => {
    if (cacheSelectedOptions.includes(option)) {
      // Remove the option if it's already selected
      setCacheSelectedOptions(cacheSelectedOptions.filter((o) => o !== option));
    } else {
      // Add the option to the selection
      setCacheSelectedOptions([...cacheSelectedOptions, option]);
    }

    if (option === "ALL") {
      setCacheSelectedOptions([]);
    }
  };

  const handleOnApply = () => {
    setIsOpen(false);
    onOptionSelect(cacheSelectedOptions);
    handleApply(cacheSelectedOptions);
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    setCacheSelectedOptions(selectedOption);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setCacheSelectedOptions(selectedOption);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedOption]);

  // Update dropdown position based on window width
  useEffect(() => {
    const updateDropdownPosition = () => {
      const dropdownRightEdge =
        dropdownRef.current?.getBoundingClientRect().right;

      if (dropdownPosition === "right-0") return;

      if (dropdownRightEdge && dropdownRightEdge > window.innerWidth) {
        setDropdownPosition("right-0");
      } else {
        setDropdownPosition("left-0");
      }
    };

    updateDropdownPosition();

    window.addEventListener("resize", updateDropdownPosition);
    return () => {
      window.removeEventListener("resize", updateDropdownPosition);
    };
  }, [dropdownPosition, isOpen]);

  // Filter options based on search query
  useEffect(() => {
    const newFilteredOptions = options.filter(
      (option) =>
        option && option.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(newFilteredOptions);
  }, [searchQuery, options]);

  return (
    <div className={`relative ${className}`}>
      <button
        className={`relative group transition-all duration-200 focus:overflow-visible p-2 overflow-hidden flex px-[10px] py-[7px] justify-between items-center rounded-[8px] ${buttonWidth} ${buttonHeight}  ${buttonTextColor} ${buttonBgColor} ${buttonBorderColor} ${className}`}
        onClick={handleToggleDropdown}
      >
        <span
          className={`font-primary text-sm not-italic font-normal whitespace-nowrap ${buttonTextSize}`}
        >
          {(cacheSelectedOptions?.length || 0) >= 2
            ? "Multiple values"
            : cacheSelectedOptions?.length === 1
            ? parseReportFilterName(cacheSelectedOptions[0])
            : label}
        </span>
        <ArrowDown className={buttonTextColor} />
      </button>

      <div
        className={`absolute flex flex-col rounded-[8px] bg-base-white shadow-md z-10 transition-opacity duration-200 ${dropdownPosition} ${dropdownBg} ${dropdownOffset} ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        ref={dropdownRef}
      >
        <div className="px-1 pt-1">
          <input
            className={`shadow appearance-none rounded w-full py-1 px-3 font-primary text-primaryText leading-tight focus:outline-none focus:shadow-outline ${buttonBgColor}`}
            id="searchField"
            type="text"
            placeholder="Search"
            value={searchQuery} // Bind search query to input value
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
          />
        </div>

        <div className="max-h-[200px] overflow-auto p-s flex flex-col gap-1 mr-1 rounded-[8px]">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <span
                key={index}
                className={`flex p-[4px] items-center self-stretch active:bg-custom-deepCharcoal active:text-primaryText rounded-[6px] cursor-pointer ${dropdownText} ${
                  cacheSelectedOptions.includes(option) ||
                  cacheSelectedOptions.length === 0
                    ? "bg-custom-deepCharcoal text-primaryText"
                    : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                <p className="font-primary text-sm not-italic font-normal leading-[120%] select-none whitespace-nowrap">
                  {parseReportFilterName(option)}
                </p>
              </span>
            ))
          ) : (
            <p className="text-center p-2 font-primary text-sm">
              No options found
            </p>
          )}
        </div>

        <div
          className={`flex justify-center gap-4 p-2 ${
            isOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <Button
            className={`!h-[28px]`}
            bgColor={buttonBgColor}
            textColor={buttonTextColor}
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
          <Button
            className="!h-[28px]"
            bgColor={buttonBgColor}
            textColor={buttonTextColor}
            onClick={handleOnApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterDropDown;
