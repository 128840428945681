import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setCurrentReportFilterCategory,
  setReportFilterOpened,
} from "../../redux/slices/globalSlice";
import {
  fetchPhotos,
  fetchReportData,
  fetchReportDataInfo,
  fetchReportFilterList,
  setCurrentReport,
  updateReportFilter,
} from "../../redux/slices/reportSlice";

import {
  getReportAliasFromLocation,
  processTableauReport,
} from "../../utils/report";

import SelectThemePopup from "../Theme/SelectThemePopup";
import CustomReport from "./CustomReport";
import EmbeddedReport from "./EmbedReport";
import ReportControls from "./ReportControls";
import ReportFilter from "./ReportFilter";
import TutorialVideo from "./TutorialVideo";

const Report = () => {
  // Use useAppDispatch to use dispatch
  const dispatch = useAppDispatch();

  // Use useLocation to get query parameters
  const location = useLocation();

  // Get current page type
  const { currentPage, isReportFilterOpened } = useAppSelector(
    (state) => state.global
  );

  // Get reports from the Redux store
  const { currentReport, reports, selectedFilters, selectedDateRange } =
    useAppSelector((state) => state.report);

  // State to handle theme popup and selected report
  const [isSelectThemeOpen, setIsSelectThemeOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const [isFitScreen, setIsFitScreen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const openSelectTheme = () => {
    setIsSelectThemeOpen(true);
  };

  const closeSelectTheme = () => {
    setIsSelectThemeOpen(false);
  };

  const openReportFilter = (category: ReportFilterCategory) => {
    dispatch(setCurrentPage(PageType.ReportFilter));
    dispatch(setCurrentReportFilterCategory(category));
    dispatch(setReportFilterOpened(true));
  };

  const closeReportFilter = () => {
    dispatch(setCurrentPage(PageType.Report));
    dispatch(setReportFilterOpened(false));
  };

  const openVideo = () => {
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
  };

  useEffect(() => {
    const reportAlias = getReportAliasFromLocation(location);
    const report = processTableauReport(reports, reportAlias);

    dispatch(setCurrentReport(report));
    dispatch(fetchReportDataInfo({ reportName: report?.alias || "" }));
  }, [dispatch, location, reports]);

  useEffect(() => {
    if (currentPage === PageType.Report) {
      dispatch(setReportFilterOpened(false));
    }
  }, [currentPage, dispatch]);

  useEffect(() => {
    dispatch(
      updateReportFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );

    dispatch(fetchPhotos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!currentReport?.alias || Object.keys(selectedFilters).length === 0)
      return;

    dispatch(
      fetchReportData({
        reportName: currentReport.alias,
        filters: selectedFilters,
      })
    );
    dispatch(fetchReportFilterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.alias, dispatch]);

  return (
    <div
      className={`flex flex-col gap-[24px]${
        isReportFilterOpened ? "overflow-hidden" : ""
      }     
      ${isFitScreen ? "h-[100vh]" : ""}`}
    >
      <ReportControls
        openSelectTheme={openSelectTheme}
        openVideo={openVideo}
        openReportFilter={openReportFilter}
        selectedReport={currentReport}
      />

      {/* Render the embedded report if found */}
      {currentReport ? (
        currentReport.reportType === "tableau" ? (
          <EmbeddedReport
            reportName={currentReport.reportName}
            reportUrl={currentReport.tableauUrl}
          />
        ) : (
          <CustomReport
            reportName={currentReport.reportName}
            isFilterOpen={isReportFilterOpened}
            isFitScreen={isFitScreen}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            setIsFitScreen={setIsFitScreen}
          />
        )
      ) : (
        <div className="mt-4">
          <p className="text-center text-neutral-700">
            No report found for the given alias.
          </p>
        </div>
      )}

      <SelectThemePopup
        isPopupOpen={isSelectThemeOpen}
        closePopup={closeSelectTheme}
      />

      <ReportFilter
        isPopupOpen={isReportFilterOpened}
        closePopup={closeReportFilter}
      />

      <TutorialVideo isPopupOpen={isVideoOpen} closePopup={closeVideo} />
    </div>
  );
};

export default Report;
