import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setCurrentReportFilterCategory,
  setMenuOpened,
  setReportFilterOpened,
} from "../../redux/slices/globalSlice";
import {
  clearFilter,
  updatedSelectedDateType,
  updateSelectedDateRange,
} from "../../redux/slices/reportSlice";

import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";

import IconButton from "../../components/Common/IconButton";
import IconToggle from "../../components/Common/IconToggle";
import ArrowBackIcon from "../../components/icons/ArrowBack";
import BookmarkIcon from "../../components/icons/BookmarkIcon";
import CalenderIcon from "../../components/icons/CalenderIcon";
import FilterResetIcon from "../../components/icons/FilterResetIcon";
import MenuIcon from "../../components/icons/MenuIcon";
import StarIcon from "../../components/icons/StarIcon";
import StarIconEmpty from "../../components/icons/StarIconEmpty";
import SelectThemeButton from "../../components/Theme/SelectThemeButton";

interface HeaderProps {
  openThemeSelectPopup: () => void;
}

const Header = ({ openThemeSelectPopup }: HeaderProps) => {
  // Get the current location using the useLocation hook
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage, isMenuOpened } = useAppSelector((state) => state.global);
  const { currentReport, appliedDateType, selectedFilters } = useAppSelector(
    (state) => state.report
  );
  const [headerTitle, setHeaderTitle] = useState<string>(PageType.Home);

  const toggleMenu = () => {
    dispatch(setMenuOpened(!isMenuOpened));
    if (!isMenuOpened) {
      dispatch(setCurrentPage(PageType.SideMenu));
    }
  };

  const handleBack = () => {
    switch (currentPage) {
      case PageType.Report:
        navigator("/");
        break;
      case PageType.SideMenu:
        dispatch(setMenuOpened(false));
        handleSideMenuBack();
        break;
      case PageType.ReportFilter:
        dispatch(setCurrentPage(PageType.Report));
        handleFilterBack();
        break;
      default:
        break;
    }
  };

  const handleSideMenuBack = () => {
    const path = location.pathname;
    if (path === "/") {
      dispatch(setCurrentPage(PageType.Home));
      setHeaderTitle(PageType.Home);
    } else if (path === "/report") {
      dispatch(setCurrentPage(PageType.Report));
      setHeaderTitle(currentReport?.reportName || "");
    } else if (path === "/competitions") {
      dispatch(setCurrentPage(PageType.Competition));
      setHeaderTitle(PageType.Competition);
    }
  };

  const handleFilterBack = () => {
    const dateFilter = selectedFilters["Date"];
    if (!dateFilter) return;
    dispatch(updatedSelectedDateType(appliedDateType));
    dispatch(updateSelectedDateRange(dateFilter));
  };

  const resetFilter = () => {
    dispatch(clearFilter());
  };

  useEffect(() => {
    const titleMap: Record<string, string> = {
      [PageType.Report]: currentReport?.reportName || "",
      [PageType.Home]: PageType.Home,
      [PageType.ReportFilter]: PageType.ReportFilter,
      [PageType.Competition]: PageType.Competition,
    };
    setHeaderTitle(titleMap[currentPage] || "");
  }, [currentPage, currentReport]);

  useEffect(() => {
    const pageMap: Record<string, PageType> = {
      "/": PageType.Home,
      "/report": PageType.Report,
      "/competitions": PageType.Competition,
    };
    const newPage = pageMap[location.pathname];
    if (newPage) {
      dispatch(setCurrentPage(newPage));
    }
  }, [dispatch, location]);

  const renderMenuIcon = () => {
    if (currentPage === PageType.Home || currentPage === PageType.Competition) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={toggleMenu}
          className="hover:bg-transparent"
        >
          <MenuIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    } else if (
      currentPage === PageType.Report ||
      currentPage === PageType.SideMenu ||
      currentPage === PageType.ReportFilter
    ) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={handleBack}
          className="hover:bg-transparent"
        >
          <ArrowBackIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    }
  };

  const renderFavoriteIcon = () => {
    return (
      currentPage === PageType.Report && (
        <IconToggle
          iconOn={<StarIcon className="text-primary" />}
          iconOff={<StarIconEmpty className="text-primaryText" />}
        ></IconToggle>
      )
    );
  };

  const renderHeaderAction = () => {
    if (currentPage === PageType.ReportFilter)
      return (
        <div className="flex items-center py-s gap-s">
          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent"
            onClick={() => {}}
          >
            <BookmarkIcon className="text-primaryText active:text-primary" />
          </IconButton>

          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent"
            onClick={resetFilter}
          >
            <FilterResetIcon className="text-primaryText active:text-primary" />
          </IconButton>
        </div>
      );
    else if (currentPage === PageType.Report)
      return (
        <div
          className="flex items-center py-s px-m gap-s text-primaryText active:text-primary cursor-pointer"
          onClick={() => {
            dispatch(setCurrentPage(PageType.ReportFilter));
            dispatch(setCurrentReportFilterCategory(ReportFilterCategory.Date));
            dispatch(setReportFilterOpened(true));
          }}
        >
          <h2 className="font-noto text-sm not-italic font-normal leading-[120%] whitespace-nowrap">
            {appliedDateType}
          </h2>

          <IconButton
            size={24}
            padding={0}
            className="hover:bg-transparent"
            onClick={() => {}}
          >
            <CalenderIcon className="text-primaryText active:text-primary" />
          </IconButton>
        </div>
      );
  };

  const renderTitle = () => {
    return (
      currentPage !== PageType.SideMenu && (
        <div
          className={`font-noto not-italic  ${
            headerTitle === PageType.Home
              ? "text-2xl font-bold leading-[120%]"
              : "text-lg font-semibold leading-[120%]"
          }`}
        >
          {headerTitle}
        </div>
      )
    );
  };

  const renderSeparator = () => {
    return (
      currentPage !== PageType.SideMenu &&
      currentPage !== PageType.ReportFilter && (
        <div className="w-px h-[21px] flex-shrink-0 bg-neutral-900"></div>
      )
    );
  };

  const renderThemeButton = () => {
    return (
      (currentPage === PageType.Home || currentPage === PageType.SideMenu) && (
        <SelectThemeButton openSelectTheme={openThemeSelectPopup} />
      )
    );
  };

  return (
    <header className="flex p-[12px] h-[52px] justify-between items-center bg-black">
      <div className="flex gap-2 items-center">
        {renderMenuIcon()}
        {renderSeparator()}
        {renderFavoriteIcon()}
        {renderTitle()}
      </div>

      {renderHeaderAction()}
      {renderThemeButton()}
    </header>
  );
};

export default Header;
