import { ReactNode } from "react";

interface MobilePopupProps {
  children?: ReactNode; // To render any custom content inside the popup
  isOpen: boolean; // Control the open/close state externally
  onClose: () => void; // Handle closing the popup externally
  backBg?: string;
  popupBg?: string;
  popupBorder?: string;
}

const MobilePopup = ({
  children,
  isOpen,
  onClose,
  backBg = "bg-base-black",
  popupBg,
  popupBorder,
}: MobilePopupProps) => {
  return (
    <div
      className={`fixed inset-0  z-50 flex justify-center items-end transition-opacity duration-300 ease-out ${backBg} ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
      onClick={onClose}
    >
      <div
        className={`bg-base-black w-full md:w-1/2 ring-[1px] ring-neutral-800 rounded-t-m transform transition-all duration-300 ease-out ${popupBg} ${popupBorder} ${
          isOpen
            ? "translate-y-0 scale-100 opacity-100"
            : "translate-y-full scale-95 opacity-0"
        }`}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default MobilePopup;
