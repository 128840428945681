import { useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "../../redux/hooks";

import { shareOnMobile } from "react-mobile-share";

import html2canvas from "html2canvas";

import Table from "../Common/Table";
import UserIcon from "../icons/UserIcon";
import UsersIcon from "../icons/UsersIcon";
import ReportActions from "./ReportActions";

interface CustomReportProps {
  reportName?: string;
  reportTitle?: string;
  reportType?: string;
  isFilterOpen?: boolean;
  isFitScreen: boolean;
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFitScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomReport = ({
  reportTitle,
  reportType,
  isFilterOpen,
  isFitScreen,
  isFullScreen,
  setIsFullScreen,
  setIsFitScreen,
}: CustomReportProps) => {
  const { reportData, isLoadingReportData } = useAppSelector(
    (state) => state.report
  );

  const reportRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null); // Create a ref for the Table component

  const { reportDataInfo, photos, selectedFilters } = useAppSelector(
    (state) => state.report
  );

  const groupBy =
    selectedFilters["GroupBy"] || reportDataInfo.filterDefaultGroupBy;

  const tableHeader = useMemo(() => {
    const fieldNames = reportDataInfo?.tableFieldNames;
    if (!fieldNames) return [""];

    // Filter out the "photo" field
    const filteredFieldNames = fieldNames.filter((field) => field !== "Photo");

    return ["", ...filteredFieldNames];
  }, [reportDataInfo.tableFieldNames]);

  const tableSubHeader = useMemo(() => {
    const result = [...reportData.data].pop();

    // Create a new object without the "photo" field
    const { photo, ...filteredResult } = result || {}; // Remove "photo" field using destructuring

    return { group_field: "Total", ...filteredResult };
  }, [reportData.data]);

  const tableContent = useMemo(() => {
    let result = [...reportData.data];
    result.pop();

    const tableContent = result.map((row, index) => {
      const photoLink =
        photos
          .find(
            (photo, index) =>
              photo.recordId === parseInt(row.photo) &&
              photo.category === groupBy
          )
          ?.link.replace("upload/", "upload/w_64,c_fill,g_auto/") || "";
      return { ...row, photo: photoLink };
    });

    return tableContent.sort(
      (row1, row2) =>
        row2[reportDataInfo.tableFields[0]] -
        row1[reportDataInfo.tableFields[0]]
    );
  }, [reportData.data, photos, groupBy, reportDataInfo.tableFields]);

  const fitScreen = () => {
    if (reportRef.current) {
      if (!isFitScreen) {
        reportRef.current.style.position = "fixed";
        reportRef.current.style.width = "100%";
        reportRef.current.style.height = "100%";
        reportRef.current.style.zIndex = "30";
        reportRef.current.style.left = "0";
        reportRef.current.style.top = "0";
        reportRef.current.style.borderRadius = "0";
      } else {
        reportRef.current.style.position = "";
        reportRef.current.style.width = "";
        reportRef.current.style.height = "";
        reportRef.current.style.zIndex = "";
        reportRef.current.style.left = "";
        reportRef.current.style.top = "";
        reportRef.current.style.borderTopLeftRadius = "";
        reportRef.current.style.borderTopRightRadius = "";
      }
    }

    setIsFitScreen((prev) => !prev);
  };

  const toggleFullScreen = () => {
    if (reportRef.current) {
      if (isFullScreen) {
        const element = reportRef.current as HTMLElement & {
          webkitExitFullscreen?: () => Promise<void>;
          mozCancelFullScreen?: () => Promise<void>;
          msExitFullscreen?: () => Promise<void>;
        };

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (element.webkitExitFullscreen) {
          // Safari
          element.webkitExitFullscreen();
        } else if (element.mozCancelFullScreen) {
          // Firefox
          element.mozCancelFullScreen();
        } else if (element.msExitFullscreen) {
          // IE/Edge
          element.msExitFullscreen();
        }
      } else {
        const element = reportRef.current as HTMLElement & {
          webkitRequestFullscreen?: () => Promise<void>;
          mozRequestFullScreen?: () => Promise<void>;
          msRequestFullscreen?: () => Promise<void>;
        };

        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          // Safari
          element.webkitRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          // Firefox
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE/Edge
          element.msRequestFullscreen();
        }
      }
    }
  };

  const handleShareSC = () => {
    const tableElement = tableRef.current;

    if (tableElement) {
      setTimeout(() => {
        html2canvas(tableElement).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          console.log(imgData);
          shareOnMobile({
            title: reportDataInfo.name,
            images: [imgData],
          });
        });
      }, 100); // Adjust the timeout as necessary
    }
  };

  // Listen for full screen change event
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, [setIsFullScreen]);

  return (
    <div
      ref={reportRef}
      className={`flex flex-col gap-sm bg-custom-deepCharcoal ${
        isFilterOpen ? "overflow-hidden" : ""
      } ${
        isFullScreen
          ? "bg-custom-deepCharcoal"
          : "rounded-t-ml bg-custom-deepCharcoal"
      }`}
    >
      {reportTitle && (
        <div className="text-center text-primaryText pt-[12px] pb-[6px] font-primary text-lg not-italic font-semibold leading-[120%] border-b-[0.6px] border-custom-white-10">
          {reportTitle}
        </div>
      )}

      <ReportActions
        onFitScreen={fitScreen}
        onFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
        isFitScreen={isFitScreen}
        handleShareSC={handleShareSC}
      />
      <div ref={tableRef}>
        <Table
          isFullScreen={isFullScreen}
          isFitScreen={isFitScreen}
          loading={isLoadingReportData}
          tableHeader={tableHeader}
          tableSubHeader={tableSubHeader}
          tableContent={tableContent}
          className={`${isFullScreen ? "!border-none" : "border-rounded"}`}
          defaultIcon={
            groupBy === "Rep" ? (
              <UserIcon className="text-primaryText w-[28px] h-[28px] min-w-[28px]" />
            ) : (
              <UsersIcon className="text-primaryText w-[28px] h-[28px] min-w-[28px]" />
            )
          }
        />
      </div>
    </div>
  );
};

export default CustomReport;
