import { Location } from "react-router-dom";
import { IReportList } from "../interfaces/reportList";
import { getQueryParam } from ".";
import { ReportType } from "../const/enums/ReportType";

export const processTableauReport = (
  reports: IReportList[],
  reportAlias: string | null
) => {
  // If report alias is empty, return null
  if (!reportAlias || reports.length === 0) return null;

  // Flatten the nested report categories into a single array of reports
  const flattenedReports = reports.flatMap((category) => category.reports);

  // Find the report by its alias
  const foundReport = flattenedReports.find(
    (report) => report.alias === reportAlias
  );

  if (foundReport) {
    if (foundReport.reportType === ReportType.Custom) return foundReport;

    const parseReport = {
      ...foundReport,
      tableauUrl: foundReport.tableauUrl.replace(
        "10az.online.tableau.com",
        "report.vspn.app"
      ),
    };

    return parseReport;
  }

  return null;
};

export const getReportAliasFromLocation = (location: Location<any>) => {
  if (!location.search) return null;

  // Extract alias from query params
  const reportAlias = getQueryParam(location, "alias");

  return reportAlias;
};

export const parseReportFilterName = (fieldName: string) => {
  if (fieldName === "0.0") {
    return "False";
  } else if (fieldName === "1.0") {
    return "True";
  } else {
    return fieldName;
  }
};

// Function to convert selected filters to URLSearchParams
export const toURLSearchParams = (selectedFilters: {
  [key: string]: string | string[];
}) => {
  const params = new URLSearchParams();
  for (const key in selectedFilters) {
    const value = selectedFilters[key];
    if (Array.isArray(value)) {
      value.forEach((item) => params.append(key, item));
    } else {
      params.append(key, value);
    }
  }
  return params.toString();
};
