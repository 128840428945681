import { useEffect, useMemo, useState } from "react";
import { Label, PanelHeader } from ".";
import Button from "../../Common/Button";
import DropDown from "../../Common/DropDown";
import ReportDateRangePicker from "./FilterDatePicker";

import { DateDirection } from "../../../const/enums/DateDirection";
import { SelectedDateType } from "../../../const/enums/SelectedDateType";
import { TimeUnit } from "../../../const/enums/TimeUnit";
import { dateRanges } from "../../../data/ReportFilter";
import { IDateRange } from "../../../interfaces/dateRange";
import {
  calculateDateRange,
  formatDate,
  getDateDirectionFromString,
  getDateUnitFromString,
} from "../../../utils/date";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  updatedSelectedDateType,
  updateSelectedDateRange,
} from "../../../redux/slices/reportSlice";

const DateRangeWithOptions = ({
  selectedDateType,
  setSelectedDateRange,
}: {
  selectedDateType: string;
  setSelectedDateRange: (range: IDateRange) => void;
}) => {
  const dispatch = useAppDispatch();
  const [selectedDateDirection, setSelectedDateDirection] = useState(
    DateDirection.Last
  );
  const [selectedDateLength, setSelectedDateLength] = useState(1);
  const [selectedDateUnit, setSelectedDateUnit] = useState(TimeUnit.Days);

  const RangeDateDirection = [DateDirection.Last, DateDirection.Next];
  const RangeDateType = [TimeUnit.Days, TimeUnit.Weeks, TimeUnit.Months];

  const handleChangeLastNext = () => {
    dispatch(updatedSelectedDateType(SelectedDateType.LastNext));
    const dateRange: IDateRange = calculateDateRange(
      selectedDateDirection,
      selectedDateLength,
      selectedDateUnit
    );
    setSelectedDateRange(dateRange);
  };

  return (
    <div className="flex flex-col gap-[32px]">
      <div className="grid grid-cols-3 gap-[16px] gap-y-[32px] items-end">
        <div className="flex flex-col gap-[4px]">
          <Label label="Last/Next" />

          <DropDown
            options={RangeDateDirection}
            selectedOption={selectedDateDirection}
            onOptionSelect={(option) => {
              const direction = getDateDirectionFromString(option);
              setSelectedDateDirection(direction);
              handleChangeLastNext();
            }}
            label="Select Report"
            className="w-[100%]"
            buttonBgColor={
              selectedDateType === "Last/Next"
                ? "bg-primary"
                : "bg-custom-darkTeal"
            }
            buttonTextColor={
              selectedDateType === "Last/Next"
                ? "text-base-white"
                : "text-primaryText"
            }
            buttonHeight="h-[38px]"
            buttonWidth="!w-[100%]"
            dropdownOffset="top-[42px]"
            dropdownBg="bg-custom-dropdownBg"
            dropdownText="text-custom-dropdownText"
          />
        </div>

        <div className="flex flex-col gap-[4px]">
          {/* How many days or weeks, or months */}
          <DropDown
            options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            selectedOption={selectedDateLength.toString()}
            onOptionSelect={(option) => {
              setSelectedDateLength(parseInt(option));
              handleChangeLastNext();
            }}
            label="Select Report"
            className="w-[100%]"
            buttonBgColor={
              selectedDateType === "Last/Next"
                ? "bg-primary"
                : "bg-custom-darkTeal"
            }
            buttonTextColor={
              selectedDateType === "Last/Next"
                ? "text-base-white"
                : "text-primaryText"
            }
            buttonHeight="h-[38px]"
            buttonWidth="!w-[100%]"
            dropdownOffset="top-[42px]"
            dropdownBg="bg-custom-dropdownBg"
            dropdownText="text-custom-dropdownText"
          />
        </div>

        <div className="flex flex-col gap-[4px]">
          <DropDown
            options={RangeDateType}
            selectedOption={selectedDateUnit}
            onOptionSelect={(option) => {
              const unit = getDateUnitFromString(option);
              setSelectedDateUnit(unit);
              handleChangeLastNext();
            }}
            label="Select Report"
            className="w-[100%]"
            buttonBgColor={
              selectedDateType === "Last/Next"
                ? "bg-primary"
                : "bg-custom-darkTeal"
            }
            buttonTextColor={
              selectedDateType === "Last/Next"
                ? "text-base-white"
                : "text-primaryText"
            }
            buttonHeight="h-[38px]"
            buttonWidth="!w-[100%]"
            dropdownOffset="top-[42px]"
            dropdownBg="bg-custom-dropdownBg"
            dropdownText="text-custom-dropdownText"
          />
        </div>
      </div>
    </div>
  );
};

const DateRangeButtons = ({
  selectedDateType,
  selectedDateRange,
  setSelectedDateRange,
}: {
  selectedDateType: string;
  selectedDateRange: IDateRange;
  setSelectedDateRange: (range: IDateRange) => void;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="grid grid-cols-2 gap-[16px] gap-y-[16px]">
      {dateRanges.map((dateRange, index) => {
        const isSelected = dateRange.name === selectedDateType;

        return (
          <Button
            bgColor={isSelected ? "bg-primary" : "bg-custom-darkTeal"}
            activeColor="active:bg-neutral-200"
            className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm"
            onClick={() => {
              dispatch(updatedSelectedDateType(dateRange.name));
              setSelectedDateRange(dateRange.range);
            }}
            key={index}
          >
            <span
              className={`font-primary text-left w-full text-sm not-italic font-normal leading-[20px] ${
                isSelected ? "text-base-white" : "text-primaryText"
              }`}
            >
              {dateRange.name}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

const SelectedRange = ({
  selectedDateType,
  selectedDateRange,
  onClick,
  selected,
}: {
  selectedDateType: string;
  selectedDateRange: IDateRange;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <div className="flex flex-col gap-[4px]">
      <Label label="Date Range" />

      <Button
        bgColor={selected ? "bg-primary" : "bg-custom-darkTeal"}
        activeColor="active:bg-neutral-200"
        className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm"
        onClick={onClick}
        textColor={selected ? "text-base-white" : "text-primaryText"}
      >
        <span
          className={`font-primary text-m not-italic font-medium leading-[120%] text-primaryText`}
        >
          {selectedDateRange.from.getTime() === selectedDateRange.to.getTime()
            ? formatDate(selectedDateRange.from)
            : `${formatDate(selectedDateRange.from)} ~ ${formatDate(
                selectedDateRange.to
              )}`}
        </span>
      </Button>
    </div>
  );
};

const DateFilters = () => {
  const dispatch = useAppDispatch();
  const { selectedFilters, selectedDateType, selectedDateRange } =
    useAppSelector((state) => state.report);

  const [isCalenderOpen, setIsCalendarOpen] = useState(false);
  const RangeDateType = [TimeUnit.Days, TimeUnit.Weeks, TimeUnit.Months];

  const closeCalendar = () => {
    setIsCalendarOpen(false);
  };

  const handleDateRangeChanged = (range: IDateRange) => {
    const selectedRange = [formatDate(range.from), formatDate(range.to)];
    dispatch(updateSelectedDateRange(selectedRange));
  };

  useEffect(() => {
    const dateFilter = selectedFilters["Date"];
    if (!dateFilter) return;

    dispatch(updateSelectedDateRange(dateFilter));
  }, [dispatch, selectedFilters]);

  useEffect(() => {
    const range = dateRanges.find(
      (rangeType) => rangeType.name === selectedDateType
    )?.range;

    if (range) {
      const selectedRange = [formatDate(range.from), formatDate(range.to)];
      dispatch(updateSelectedDateRange(selectedRange));
    }
  }, [dispatch, selectedDateType]);

  const parsedSelectedDateRange = useMemo(() => {
    return {
      from: new Date(selectedDateRange[0]),
      to: new Date(selectedDateRange[1]),
    };
  }, [selectedDateRange]);

  return (
    <div className="w-full bg-custom-deepCharcoal rounded-[8px] gap-[24px] pb-[16px] shadow-8dp-umbra">
      <div className="flex flex-col w-full gap-[16px] px-[8px] pb-[24px]  border-b-[1px] border-custom-white-10">
        <PanelHeader header="DATES" />

        <SelectedRange
          selectedDateType={selectedDateType}
          selectedDateRange={parsedSelectedDateRange}
          onClick={() => {
            setIsCalendarOpen(true);
          }}
          selected={selectedDateType === SelectedDateType.Custom}
        />

        <DateRangeButtons
          selectedDateType={selectedDateType}
          selectedDateRange={parsedSelectedDateRange}
          setSelectedDateRange={handleDateRangeChanged}
        />

        <DateRangeWithOptions
          selectedDateType={selectedDateType}
          setSelectedDateRange={handleDateRangeChanged}
        />
      </div>

      <div className="flex flex-col gap-[16px] px-[8px]">
        <PanelHeader header="GRAPH BY" />

        <div className="flex flex-col gap-[4px]">
          <Label label="Date Group By" />

          <DropDown
            options={RangeDateType}
            selectedOption={RangeDateType[0]}
            onOptionSelect={() => {}}
            label="Select Report"
            className="w-[100%]"
            buttonBgColor="bg-custom-darkTeal"
            buttonTextColor="text-primaryText"
            buttonHeight="h-[38px]"
            buttonWidth="!w-[100%]"
            dropdownOffset="top-[42px]"
            dropdownBg="bg-custom-dropdownBg"
            dropdownText="text-custom-dropdownText"
          />
        </div>
      </div>

      <ReportDateRangePicker
        selectedDateType={selectedDateType}
        selectedDateRange={parsedSelectedDateRange}
        setSelectedDateRange={handleDateRangeChanged}
        isPopupOpen={isCalenderOpen}
        closePopup={closeCalendar}
      />
    </div>
  );
};

export default DateFilters;
