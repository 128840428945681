import { IPhoto } from "./../../interfaces/photo";
import { IFilterList } from "../../interfaces/filterList";
import { IReport } from "../../interfaces/report";
import { ReportDataInfo } from "../../interfaces/reportDataInfo";

const parseReports = (payload: any[]) => {
  return payload.map((report, index) => {
    return {
      id: report.id,
      reportName: report.report_name,
      alias: report.alias,
      reportType: report.report_type,
      category: report.category,
      tableauUrl: report.tableau_url,
    };
  });
};

// Function to group reports by category name
const groupReportsByCategory = (
  reports: any[]
): { categoryName: string; reports: IReport[] }[] => {
  return Object.values(
    reports.reduce((acc, report) => {
      const categoryName = report.category.name;

      if (!acc[categoryName]) {
        acc[categoryName] = {
          categoryName,
          reports: [],
        };
      }

      acc[categoryName].reports.push(report);

      return acc;
    }, {} as { [key: string]: { categoryName: string; reports: IReport[] } })
  );
};

const parseFilterData = (filterData: {
  [key: string]: (string | null)[];
}): IFilterList => {
  const parsedData: IFilterList = {};

  // Loop through each key in the filterData object
  for (const key in filterData) {
    if (filterData.hasOwnProperty(key)) {
      // Replace null values with 'NULL'
      parsedData[key] = filterData[key].map((value) =>
        value === null ? "NULL" : value
      );
    }
  }

  return parsedData;
};

const parseReportDataInfo = (reportData: any) => {
  return {
    name: reportData.report_name,
    displayType: reportData.report_display_type,
    filterDefaultGroupBy: reportData.report_default_group_by,
    filterHierarchy: reportData.report_filter_hierarchy,
    filterAdditional: reportData.report_filter_additional,
    filterGroupNames: reportData.report_filter_group_names,
    filterGroupFields: reportData.report_filter_group_fields,
    tableFields: reportData.report_table_fields,
    tableFieldNames: reportData.report_table_field_names,
  } as ReportDataInfo;
};

const parsePhotos = (photos: any) => {
  return photos.map((photo: any) => {
    return {
      id: photo.id,
      category: photo.category,
      recordId: parseInt(photo.record_id),
      link: photo.cloudinary_url,
    } as IPhoto;
  });
};

export {
  groupReportsByCategory,
  parseFilterData,
  parseReports,
  parseReportDataInfo,
  parsePhotos,
};
