import { useState } from "react";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";

import CloseIcon from "../icons/CloseIcon";
import ResetIcon from "../icons/ResetIcon";

import { switchTheme } from "../../utils/theme";

interface SelectThemePopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

const themes = [
  {
    theme: "sunset",
    name: "SUNSET GLOW",
    bg: "#1A535C",
    primary: "#FF6B6B",
    secondary: "#FFE66D",
    color1: "#F7FFF7",
    color2: "#4ECDC4",
  },
  {
    theme: "neon",
    name: "NEON VIBES",
    bg: "#EAEAEA",
    primary: "#FF2E63",
    secondary: "#08D9D6",
    color1: "#FFFFFF",
    color2: "#252A34",
  },
  {
    theme: "light",
    name: "LIGHT SKY",
    bg: "#F3F4F7",
    primary: "#3462FF",
    secondary: "#5EB525",
    color1: "#000000",
    color2: "#F1F1F1",
  },
  {
    theme: "earthy",
    name: "EARTHY TONES",
    bg: "#FBEEC1",
    primary: "#DB0000",
    secondary: "#FFFFFF",
    color1: "#4D4D4D",
    color2: "#DAAD86",
  },
];

const SelectThemePopup = ({
  isPopupOpen,
  closePopup,
}: SelectThemePopupProps) => {
  const [selectedTheme, setSelectedTheme] = useState(0);

  const handleOnApply = () => {
    switchTheme(themes[selectedTheme].theme);
    closePopup();
  };

  return (
    <MobilePopup
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-black40"
      popupBg="bg-custom-deepCharcoal"
      popupBorder="ring-secondary-900"
    >
      <div className="flex flex-col gap-m">
        <div className="flex flex-col items-center justify-between pt-4 pb-6 px-4 gap-6">
          <div className="flex flex-col w-full gap-1">
            <div className="flex w-full justify-between items-center py-[5px]">
              <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Select Theme
              </h2>

              <IconButton onClick={closePopup} padding={0}>
                <CloseIcon className="text-primaryText" />
              </IconButton>
            </div>

            <h3 className="text-custom-white-b3 font-primary text-xs not-italic font-normal leading-[120%]">
              Customize background, by choosing a color template
            </h3>
          </div>

          <div className="grid w-full grid-cols-4 gap-3 items-center">
            {themes.map((theme, index) => {
              return (
                <div className="flex flex-col gap-3 h-[100%]" key={index}>
                  <div
                    className={`p-[10px] rounded-[8px] transition ${
                      selectedTheme === index
                        ? "border-[3px] border-solid border-primary"
                        : "border-[3px] border-solid border-transparent"
                    }`}
                    style={{ backgroundColor: theme.bg }} // Dynamically set the background color
                    onClick={() => {
                      setSelectedTheme(index);
                    }}
                  >
                    <div className="grid grid-cols-2">
                      <div
                        className="w-[100%] h-[24px]"
                        style={{ backgroundColor: theme.primary }} // Dynamically set the background color
                      ></div>
                      <div
                        className="w-[100%] h-[24px]"
                        style={{ backgroundColor: theme.secondary }} // Dynamically set the background color
                      ></div>

                      <div
                        className="w-[100%] h-[24px]"
                        style={{ backgroundColor: theme.color1 }} // Dynamically set the background color
                      ></div>

                      <div
                        className="w-[100%] h-[24px]"
                        style={{ backgroundColor: theme.color2 }} // Dynamically set the background color
                      ></div>
                    </div>
                  </div>

                  <h3 className="text-custom-white-b3 px-3 text-center font-primary text-[12px] not-italic font-normal leading-[120%]">
                    {theme.name}
                  </h3>
                </div>
              );
            })}
          </div>

          <div className="flex gap-4 w-full">
            <Button
              bgColor="bg-primary"
              activeColor="active:bg-neutral-200"
              className="py-sm px-m max-h-[40px] w-[100%] rounded-sm"
              onClick={handleOnApply}
            >
              <span className="font-primary text-base-white text-sm not-italic font-medium leading-[120%]">
                Apply
              </span>
            </Button>

            <Button
              bgColor="bg-secondary"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] max-h-[40px]"
              onClick={() => {
                setSelectedTheme(-1);
                switchTheme("");
              }}
            >
              <span className="font-primary text-custom-buttonText text-sm not-italic font-medium leading-[120%]">
                Reset
              </span>

              <ResetIcon className="text-custom-buttonText" />
            </Button>
          </div>
        </div>
      </div>
    </MobilePopup>
  );
};

export default SelectThemePopup;
