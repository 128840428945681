import React from "react";
import { Navigate } from "react-router-dom";
import { L_JWT_TOKEN } from "../../const/localstorage";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = localStorage.getItem(L_JWT_TOKEN);
  return token ? <>{children}</> : <Navigate to="/login" />;
};

export default ProtectedRoute;
