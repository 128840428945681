import React, { ReactNode, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setMenuOpened } from "../../redux/slices/globalSlice";

import { useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import SideMenu from "../../components/SideMenu";
import SelectThemePopup from "../../components/Theme/SelectThemePopup";
import Header from "./Header";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isMenuOpened } = useAppSelector((state) => state.global);
  const [isSelectThemeOpen, setIsSelectThemeOpen] = useState(false);
  const [headerHidden, setHeaderHidden] = useState(false);

  const openSelectTheme = () => {
    setIsSelectThemeOpen(true);
  };

  const closeSelectTheme = () => {
    setIsSelectThemeOpen(false);
  };

  const closeMenu = () => {
    dispatch(setMenuOpened(false));
  };

  useEffect(() => {
    setHeaderHidden(location.pathname === "/login");
  }, [location]);

  useEffect(() => {
    const button = document.getElementById("chatbase-bubble-button");
    if (button) {
      button.style.display = !isMenuOpened ? "block" : "none"; // Hides the button
    }
  }, [isMenuOpened]);

  return (
    <div className="flex flex-col h-screen bg-black text-primaryText">
      {!headerHidden && <Header openThemeSelectPopup={openSelectTheme} />}

      <main className={`flex-1`}>{children}</main>

      <SideMenu isMenuOpen={isMenuOpened} closeMenu={closeMenu} />

      <NavBar isMenuOpen={isMenuOpened} />

      <SelectThemePopup
        isPopupOpen={isSelectThemeOpen}
        closePopup={closeSelectTheme}
      />
    </div>
  );
};

export default MainLayout;
