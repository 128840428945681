import { useNavigate } from "react-router-dom";
import { IReportList } from "../../interfaces/reportList";
import DropDownArrowIcon from "../icons/DropDownArrowIcon";

interface ReportListsProps {
  reports: IReportList[];
  closeMenu: () => void;
}

const ReportLists: React.FC<ReportListsProps> = ({ closeMenu, reports }) => {
  const navigate = useNavigate();
  const onReportClick = (reportAlias: string) => {
    navigate(`/report?alias=${reportAlias}`);
    closeMenu();
  };

  return (
    <div className="grid divide-y divide-custom-grey-fa">
      {reports.map((report, index) => {
        return (
          <details className="group" key={index}>
            <summary className="group-open:bg-white group-open:border-b-[0.4px] group-open:border-solid group-open:border-b-custom-grey-a3 transition duration-300 outline-none px-[12px] py-m  flex justify-between items-center font-medium cursor-pointer list-none">
              <span className="text-base-black font-primary text-lg not-italic font-semibold leading-[120%]">
                {report.categoryName}
              </span>
              <span className="transition group-open:rotate-180">
                <DropDownArrowIcon className="text-base-black" />
              </span>
            </summary>

            <div className="flex flex-col bg-white group-open:animate-fadeIn">
              {report.reports.map((report, index) => {
                return (
                  <div
                    className="px-[24px] py-[8px] text-base-black hover:bg-neutral-200 transition font-primary text-base not-italic font-semibold leading-[120%] cursor-pointer"
                    key={index}
                    onClick={() => {
                      onReportClick(report.alias || "");
                    }}
                  >
                    {report.reportName}
                  </div>
                );
              })}
            </div>
          </details>
        );
      })}
    </div>
  );
};

export default ReportLists;
