import { useEffect, useState } from "react";

import { ReportFilterCategory } from "../../../const/enums/ReportFilterCategory";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchReportData,
  updatedAppliedDateType,
  updateReportFilter,
} from "../../../redux/slices/reportSlice";

import Button from "../../Common/Button";
import AdditionalFilters from "./AdditionalFilters";
import DateFilters from "./DateFilters";
import HierarchyFilters from "./HierarchyFilters";

interface ReportFilterProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

export const Label = ({ label }: { label: string }) => {
  return (
    <span
      className={`text-primaryText font-primary text-sm not-italic font-semibold leading-[120%] ${"text-base-white"}`}
    >
      {label}
    </span>
  );
};

export const PanelHeader = ({ header }: { header: string }) => {
  return (
    <div className="text-primaryText py-[16px] text-center font-primary text-base not-italic font-semibold leading-[120%] border-b-[1px] border-custom-white-10 ">
      {header}
    </div>
  );
};

const ReportFilter = ({ isPopupOpen, closePopup }: ReportFilterProps) => {
  const dispatch = useAppDispatch();
  const { currentReportFilterCategory } = useAppSelector(
    (state) => state.global
  );

  const {
    currentReport,
    selectedFilters,
    selectedDateType,
    selectedDateRange,
  } = useAppSelector((state) => state.report);

  const [currentFilterType, setCurrentFilterType] = useState(
    currentReportFilterCategory
  );

  useEffect(() => {
    setCurrentFilterType(currentReportFilterCategory);
  }, [currentReportFilterCategory]);

  const renderReportCategories = () => {
    return Object.values(ReportFilterCategory).map((category) => (
      <Button
        key={category}
        bgColor={
          currentFilterType === category
            ? "bg-primary"
            : "bg-custom-deepCharcoal"
        }
        activeColor="active:bg-neutral-200"
        className="py-sm px-[14px] max-h-[40px] rounded-sm"
        onClick={() => setCurrentFilterType(category)}
      >
        <span
          className={`font-primary text-m not-italic font-medium leading-[120%] ${
            currentFilterType === category
              ? "text-base-white"
              : "text-custom-grey-a3"
          }`}
        >
          {category}
        </span>
      </Button>
    ));
  };

  const renderFilters = () => {
    switch (currentFilterType) {
      case ReportFilterCategory.Date:
        return <DateFilters />;
      case ReportFilterCategory.Hierarchy:
        return <HierarchyFilters />;
      case ReportFilterCategory.Additional:
        return <AdditionalFilters />;
    }
  };

  const onHandleApplyFilters = () => {
    if (!currentReport?.reportName) return;
    dispatch(updatedAppliedDateType(selectedDateType));

    dispatch(
      updateReportFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );

    dispatch(
      fetchReportData({
        reportName: currentReport?.alias,
        filters: selectedFilters,
      })
    );
    closePopup();
  };

  return (
    <div
      className={`fixed top-[52px] left-[0px] w-full h-full bg-black transition duration-300 ease-in-out z-30 ${
        isPopupOpen
          ? "opacity-100 scale-100 translate-y-[0%]"
          : "opacity-0 scale-95 translate-y-[100%] pointer-events-none"
      }`}
      style={{ height: "calc(100vh - 52px)" }}
    >
      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 overflow-y-scroll px-[12px] pt-[12px] pb-[72px]">
        <div className="flex gap-[17px]">{renderReportCategories()}</div>

        {renderFilters()}

        <Button
          bgColor="bg-primary"
          activeColor="active:bg-neutral-200"
          className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm mt-[16px]"
          onClick={onHandleApplyFilters}
        >
          <span
            className={`font-primary text-m not-italic font-medium leading-[120%] text-base-white`}
          >
            Apply
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ReportFilter;
