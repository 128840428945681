import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Common/Button";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/slices/authSlice";

import logoIcon from "../../assets/logo_white.png";

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { error, loading, token } = useAppSelector((state) => state.auth);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  };

  // Check if a token exists, and if so, redirect to home
  useEffect(() => {
    if (token) {
      navigate("/"); // Redirect to the protected route (home in this case)
    }
  }, [navigate, token]);

  return (
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 py-6 flex flex-col items-center justify-center h-full min-h-screen">
      <img src={logoIcon} alt="Vivint Logo" className="w-[72px] mb-[48px]" />

      {error && <p className="text-error-100">{error}</p>}
      <form
        onSubmit={handleLogin}
        className="w-full max-w-96 rounded-lg shadow-md mb-16"
      >
        <div className="mb-4">
          <label className="block font-primary text-primary">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="rounded-xxs w-full flex-grow-[1] bg-custom-grey-d9 p-sm pl-[16px] text-custom-grey-57 outline-none	font-primary text-sm not-italic font-medium leading-[100%]"
            placeholder="Enter your username"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block font-primary text-primary">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-xxs w-full flex-grow-[1] bg-custom-grey-d9 p-sm pl-[16px] text-custom-grey-57 outline-none	font-primary text-sm not-italic font-medium leading-[100%]"
            placeholder="Enter your password"
            required
          />
        </div>

        <Button
          buttonType="submit"
          className="w-full mt-[24px]"
          activeColor="active:bg-primary-400"
        >
          {loading ? "Logging in..." : "Login"}
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
