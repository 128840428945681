import { Location } from "react-router-dom";

// Utility function to retrieve query params by key
export const getQueryParam = (
  location: Location<any>,
  key: string
): string | null => {
  return new URLSearchParams(location.search).get(key);
};

// Function to remove empty array keys
export const removeEmptyArrays = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      // Keep only non-empty arrays or non-array values
      return !Array.isArray(value) || value.length > 0;
    })
  );
};
