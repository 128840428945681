import chatLogoIcon from "../../assets/icons/ChatLogo.png";
import leftArrowIcon from "../../assets/icons/ChevronRight.svg";

const ChatVVNTContent = () => {
  const DateLabel = () => {
    return (
      <div className="w-min px-[12px] py-[5px]  rounded-[2px] bg-custom-darkTeal text-custom-white-90 text-center font-primary text-[10px] not-italic font-bold leading-[100%] uppercase">
        Today
      </div>
    );
  };

  const MyMessage = () => {
    return (
      <div className="w-full flex gap-[12px] justify-end">
        <div className="p-[12px] rounded-tl-[6px] rounded-br-[6px] rounded-tr-[2px] rounded-bl-[6px] bg-primary text-white font-primary text-xs not-italic font-medium leading-[150%]">
          Where can I see which of my reps had their best week of the year this
          week?
        </div>

        <img
          src="https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/1aa0229c3bd025b8be09cc0ce77eeae79dfd17f0?fuid=1215720084589520081"
          alt="Avatar"
          className="w-[35px] h-[35px] rounded-[50%]"
        />
      </div>
    );
  };

  const AgentMessage = () => {
    return (
      <div className="w-full justify-start flex gap-[12px]">
        <div className="w-[35px] h-[35px] py-[11px] pl-[11px] pr-[8px] rounded-[50%] bg-base-white">
          <img
            src={chatLogoIcon}
            alt="Avatar"
            className="min-w-[16px] h-[13px]"
          />
        </div>

        <div className="max-w-[600px]">
          <div className="p-[12px] rounded-tl-[2px] rounded-br-[0] rounded-tr-[6px] rounded-bl-[0] bg-base-white text-base-black font-primary text-xs not-italic font-medium leading-[150%]">
            You can see that information by clicking this link to the BEST
            REPORT, where you will find which reps had their best week this
            year, as well as lots of other great insights about the reps and
            teams achieving new records.
          </div>

          <div className="hover:bg-custom-midnightGreen duration-200 transition flex justify-between items-center p-[12px] rounded-tl-[0] rounded-br-[6px] rounded-tr-[0] rounded-bl-[6px] bg-custom-deepCharcoal  font-primary text-xs not-italic font-semibold leading-[150%]">
            <div>
              <span className="text-custom-white-80 font-medium">
                Take me to:{" "}
              </span>
              <span className="text-primaryText font-semibold	">
                BEST REPORT
              </span>
            </div>

            <img src={leftArrowIcon} alt="Right" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="w-full flex flex-col items-center gap-[20px] p-[26px]">
        <DateLabel />

        <MyMessage />
        <MyMessage />
        <MyMessage />

        <AgentMessage />
      </div>
    </div>
  );
};

export default ChatVVNTContent;
