import axios from "axios";
import { API_BASE_URL } from "../const/config";
import { removeEmptyArrays } from "../utils";

export const getReportList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/reports/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching production reports", error);
    throw error;
  }
};

export const getTableauJwtToken = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/token/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tableau jwt token", error);
    throw error;
  }
};

export const getReportFilters = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/filters`);
    return response.data;
  } catch (error) {
    console.error("Error fetching report filters", error);
    throw error;
  }
};

export const getReportDataInfo = async (reportName: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/reportInfo?reportName=${reportName}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching report info", error);
    throw error;
  }
};

export const getFilteredReportData = async (
  reportName: string,
  filter: any
) => {
  try {
    const cleanFilter = removeEmptyArrays(filter);
    const response = await axios.post(
      `${API_BASE_URL}/report?reportName=${reportName}`,
      { filters: cleanFilter }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching report data", error);
    throw error;
  }
};

export const getFilteredReportFilter = async (
  reportName: string,
  filter: any
) => {
  try {
    const cleanFilter = removeEmptyArrays(filter);
    const response = await axios.post(
      `${API_BASE_URL}/filters?reportName=${reportName}`,
      { filters: cleanFilter }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching report data", error);
    throw error;
  }
};

export const getPhotos = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/photos`);
    return response.data;
  } catch (error) {
    console.error("Error fetching photo infos", error);
    throw error;
  }
};
