import { favoritePages, favoriteReps } from "../../data/Home";

interface FavoriteSectionProps {
  openPopup?: () => void;
}

const FavoriteSection = ({ openPopup }: FavoriteSectionProps) => {
  return (
    <div className="flex flex-col items-start gap-[12px]">
      <div className="flex justify-between	 items-center gap-[10px] self-stretch">
        <h2 className="text-primaryText text-lg not-italic font-bold leading-[120%]">
          Favorites
        </h2>

        <button
          className="flex justify-center items-center px-m py-sm"
          onClick={openPopup}
        >
          <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
            Edit
          </span>
        </button>
      </div>

      <div className="flex items-center overflow-x-auto w-full">
        <div className="flex gap-sm items-center ">
          {favoriteReps.map((favorite, index) => {
            return (
              <div
                className="flex flex-col items-center gap-[7px] rounded-[4px] bg-item-dark-gradient p-[8px] h-[123px]"
                key={index}
              >
                <img
                  src={favorite.profileAvatar}
                  alt="Profile Img"
                  srcSet=""
                  className="w-[64px] h-[64px] min-w-[64px] min-h-[64px] flex-shrink-0 rounded-[64px] border-[1px] border-solid border-neutral-700 shadow-pen-umbra-1"
                />

                <p className="text-base-white text-center font-primary text-xs not-italic font-semibold leading-[120%] max-w-[64px]">
                  {favorite.profileName}
                </p>
              </div>
            );
          })}

          {favoritePages.map((page, index) => {
            return (
              <div
                className="flex flex-col items-center gap-[7px] rounded-[4px] bg-item-dark-gradient p-[8px] h-[123px]"
                key={index}
              >
                <img
                  src={page.pageIcon}
                  alt="Production Report"
                  srcSet=""
                  className="w-[64px] h-[64px] min-w-[64px] min-h-[64px] flex-shrink-0 rounded-[64px] shadow-pen-umbra-1 bg-gradient-1"
                />

                <p className="text-base-white text-center font-primary text-xs not-italic font-semibold leading-[120%] max-w-[64px]">
                  {page.pageName}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FavoriteSection;
