import React, { useState } from "react";

interface IconToggleProps {
  iconOn: React.ReactNode;
  iconOff: React.ReactNode;
}

const IconToggle: React.FC<IconToggleProps> = ({ iconOn, iconOff }) => {
  const [toggled, setToggled] = useState(false);

  const handleToggle = () => {
    setToggled(!toggled);
  };

  return (
    <div
      className="relative inline-block w-[24px] h-[24px] flex items-center justify-center flex items-center justify-center cursor-pointer"
      onClick={handleToggle}
    >
      {/* Toggled Icon */}
      <div
        className={`absolute  transform transition-all duration-500 ease-in-out ${
          toggled ? "opacity-100 scale-100" : "opacity-0 scale-50"
        }`}
      >
        {iconOn}
      </div>

      {/* Non-Toggled Icon */}
      <div
        className={`absolute  transform transition-all duration-500 ease-in-out ${
          toggled ? "opacity-0 scale-50" : "opacity-100 scale-100"
        }`}
      >
        {iconOff}
      </div>
    </div>
  );
};

export default IconToggle;
