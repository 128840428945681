import { Competition } from "../types/Competition";
import { FavoritePage } from "../types/FavoritePage";
import { FavoriteReps } from "../types/FavoriteReps";
import { ProductionReport } from "../types/ProductionReport";
import { Trending } from "../types/Trending";

import bestReportIcon from "../assets/icons/BestReport.png";
import productionReportIcon from "../assets/icons/ProductionReport.png";
import crownIcon from "../assets/icons/Template/Crown.png";

export const favoriteReps: FavoriteReps[] = [
  {
    profileAvatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    profileName: "Adam K",
  },

  {
    profileAvatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/6ce0627880fe42f883960c7492888a6e93f7bb2b?fuid=1215720084589520081",
    profileName: "David Ritchie",
  },

  {
    profileAvatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/a5bc27e238474c3a8026069d17b3fde301968dd3?fuid=1215720084589520081",
    profileName: "Jessica K.",
  },
];

export const favoritePages: FavoritePage[] = [
  {
    pageIcon: productionReportIcon,
    pageName: "Production Report",
  },
  {
    pageIcon: bestReportIcon,
    pageName: "Best Report",
  },
];

export const competitions: Competition[] = [
  {
    competitionIcon:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/d68bb8b80286c99b90c14f7ef302d04071d0f13f?fuid=1215720084589520081",
    competitionName: "CUPD2 National",
  },
  {
    competitionIcon: crownIcon,
    competitionName: "Crown Competition",
  },
  {
    competitionIcon:
      "https://s3-alpha-sig.figma.com/img/3cae/bb10/e4e6fd4771c04a861d68d1447414177c?Expires=1727654400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Rh23VnlBuPO8Zv~P9HwlvH6E0Cni0y4TYsKOiq1Cm3FUG~v2XXyImMlTEA8TxWCYwe1mMtYw2L5k~NmJdZoEF3GpipDWzi5evSFLq2itcSHK7-HSisVhMvw1fa1B~7qTHui2r-xJQPJkML1irgk8oj6HvotpRwdtXbc-KA1TGpy0rtOpNxXBbnIX4yY8j~Lw5uW~5us~ofdLUAGaq9O8gXM9G9AL2TXkUphvUvtNcoAOenSS9rolFapGIhKxLjerIP5qFJxWEJxhxHiF1UwC52MjAGzt-vw3j~c-Qp~-dhM1JDyLNZ8v3Zvma7rwg1-qbOOVVnGym1cv0zqTIwNoWQ__",
    competitionName: "Vivint Premier League",
  },
];

export const trendings: Trending[] = [
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    label: "MONTHLY SALES STANDING",
    title: "REGION Adam Kinkler",
    info: "Leading the way this month with 230 sales.",
  },

  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/6ce0627880fe42f883960c7492888a6e93f7bb2b?fuid=1215720084589520081",
    label: "SALES COMPETITION",
    title: "REGION David Ritchie",
    info: "Won the Viper Competition",
  },

  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    label: "MONTHLY SALES STANDING",
    title: "REGION Adam Kinkler",
    info: "Leading the way this month with 230 sales.",
  },

  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    label: "RISING TALENT",
    title: "Aaron Doria",
    info: "Leading the way this month with 30 sales.",
  },
];

export const productionReport: ProductionReport[] = [
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Isaac Gates",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Adam Kinkler",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "David Ritchie",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
  {
    avatar:
      "https://www.figma.com/file/lqwHjKtArdbBCwnwWrhA3Z/image/03669a7f8519c35fbda8f1d8de54c9ecd4519cb8?fuid=1215720084589520081",
    name: "Aaron Doria",
    efp2024: 21406.5,
    efp2023: 210914,
    efpGrowth: 1,
  },
];
