import { createSlice } from "@reduxjs/toolkit";
import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";

export interface GlobalState {
  currentPage: PageType;
  currentReportFilterCategory: ReportFilterCategory;
  isReportFilterOpened: boolean;
  isMenuOpened: boolean;
  isPopupOpened: boolean;
}

const initialState: GlobalState = {
  currentPage: PageType.Home,
  currentReportFilterCategory: ReportFilterCategory.Date,
  isReportFilterOpened: false,
  isMenuOpened: false,
  isPopupOpened: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setCurrentReportFilterCategory: (state, { payload }) => {
      state.currentReportFilterCategory = payload;
    },
    setMenuOpened: (state, { payload }) => {
      state.isMenuOpened = payload;
      state.isPopupOpened = payload;
    },
    setReportFilterOpened: (state, { payload }) => {
      state.isReportFilterOpened = payload;
      state.isPopupOpened = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setCurrentReportFilterCategory,
  setMenuOpened,
  setReportFilterOpened,
  setCurrentPage,
} = globalSlice.actions;

export default globalSlice.reducer;
