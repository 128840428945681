import { DateDirection } from "../const/enums/DateDirection";
import { TimeUnit } from "../const/enums/TimeUnit";
import { IDateRange } from "../interfaces/dateRange";

export function formatDate(date: Date) {
  const month = date.getMonth() + 1; // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for day
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function formatDateStandard(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getToday(): IDateRange {
  const today = new Date();
  return {
    from: new Date(today.setHours(0, 0, 0, 0)),
    to: new Date(today.setHours(23, 59, 59, 999)),
  };
}

export function getYesterday(): IDateRange {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return {
    from: new Date(yesterday.setHours(0, 0, 0, 0)),
    to: new Date(yesterday.setHours(23, 59, 59, 999)),
  };
}

export function getFullSeason(): IDateRange {
  return {
    from: new Date(0), // January 1, 1970
    to: new Date(),
  };
}

export function getYTD(): IDateRange {
  const today = new Date();
  return {
    from: new Date(today.getFullYear(), 0, 1), // January 1 of the current year
    to: new Date(today.setHours(23, 59, 59, 999)),
  };
}

export function getLastWeek(): IDateRange {
  const today = new Date();
  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - today.getDay() - 7); // Start of last week
  const lastWeekEnd = new Date(today);
  lastWeekEnd.setDate(today.getDate() - today.getDay() - 1); // End of last week

  return {
    from: new Date(lastWeekStart.setHours(0, 0, 0, 0)),
    to: new Date(lastWeekEnd.setHours(23, 59, 59, 999)),
  };
}

export function getWTD(): IDateRange {
  const today = new Date();
  const weekStart = new Date(today);
  weekStart.setDate(today.getDate() - today.getDay()); // Start of the week

  return {
    from: new Date(weekStart.setHours(0, 0, 0, 0)),
    to: new Date(today.setHours(23, 59, 59, 999)),
  };
}

export function getLastMonth(): IDateRange {
  const today = new Date();
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

  return {
    from: new Date(lastMonthStart.setHours(0, 0, 0, 0)),
    to: new Date(lastMonthEnd.setHours(23, 59, 59, 999)),
  };
}

export function getMTD(): IDateRange {
  const today = new Date();
  return {
    from: new Date(today.getFullYear(), today.getMonth(), 1), // First day of the current month
    to: new Date(today.setHours(23, 59, 59, 999)),
  };
}

export function calculateDateRange(
  direction: DateDirection,
  count: number,
  unit: TimeUnit
) {
  const today = new Date();
  let startDate: Date = new Date();
  let endDate: Date = new Date();

  if (direction === DateDirection.Last) {
    if (unit === TimeUnit.Days) {
      startDate.setDate(today.getDate() - count);
      endDate = new Date(today);
      endDate.setHours(23, 59, 59, 999);
    } else if (unit === TimeUnit.Months) {
      startDate = new Date(today.getFullYear(), today.getMonth() - count, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
      endDate.setHours(23, 59, 59, 999);
    } else if (unit === TimeUnit.Weeks) {
      startDate.setDate(today.getDate() - count * 7);
      endDate = new Date(today);
      endDate.setHours(23, 59, 59, 999);
    }
  } else if (direction === DateDirection.Next) {
    if (unit === TimeUnit.Days) {
      startDate.setDate(today.getDate() + 1);
      endDate.setDate(today.getDate() + count);
      endDate.setHours(23, 59, 59, 999);
    } else if (unit === TimeUnit.Months) {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + count + 1, 0); // Last day of the month after the next
      endDate.setHours(23, 59, 59, 999);
    } else if (unit === TimeUnit.Weeks) {
      startDate.setDate(today.getDate() + 7);
      endDate.setDate(today.getDate() + count * 7);
      endDate.setHours(23, 59, 59, 999);
    }
  }

  return {
    from: startDate,
    to: endDate,
  };
}

export function getDateDirectionFromString(value: string): DateDirection {
  if (value in DateDirection) {
    return (
      DateDirection[value as keyof typeof DateDirection] || DateDirection.Last
    );
  }
  return DateDirection.Last;
}

export function getDateUnitFromString(value: string): TimeUnit {
  if (value in TimeUnit) {
    return TimeUnit[value as keyof typeof TimeUnit];
  }
  return TimeUnit.Days;
}

// Function to shift the month of a date by a specific number of months
export function shiftMonth(date: Date, months: number): Date {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

// Function to shift the date by a specific number of years
export function shiftYear(date: Date, years: number): Date {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

// Function to shift the date by a specific number of weeks
export function shiftWeek(date: Date, weeks: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + weeks * 7);
  return newDate;
}

// Function to shift the date by a specific number of days
export function shiftDay(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
