import Button from "../Common/Button";
import ThemeIcon from "../icons/ThemeIcon";

interface SelectThemeButtonProps {
  openSelectTheme: () => void;
}

const SelectThemeButton = ({ openSelectTheme }: SelectThemeButtonProps) => {
  return (
    <Button
      bgColor="bg-primary-gradient from-primary to-primary-900"
      activeColor="active:brightness-125"
      className="!h-[38px] flex gap-[11px] ring-1 ring-custom-white-30 ring-inset brightness-100 transition duration-400 outline-none"
      onClick={openSelectTheme}
    >
      <span className="text-base-white font-primary text-sm not-italic font-medium leading-[120%]">
        Theme
      </span>

      <ThemeIcon className="text-base-white" />
    </Button>
  );
};

export default SelectThemeButton;
