import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageType } from "../../const/enums/PageType";
import { NavBarItems } from "../../data/NavBar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setMenuOpened } from "../../redux/slices/globalSlice";

interface NavBarProps {
  isMenuOpen: boolean;
}

const NavBar = ({ isMenuOpen }: NavBarProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage } = useAppSelector((state) => state.global);

  const [selectPageIndex, setSelectPageIndex] = useState(0);

  const onHandleNavigate = (item: (typeof NavBarItems)[0], index: number) => {
    navigate(item.link);
    setSelectPageIndex(index);
    dispatch(setMenuOpened(false));
  };

  useEffect(() => {
    switch (currentPage) {
      case PageType.Home:
        setSelectPageIndex(0);
        break;
      case PageType.Competition:
        setSelectPageIndex(1);
        break;

      default:
        break;
    }
  }, [currentPage]);

  return (
    <div
      className={`fixed bottom-0 left-0 w-full bg-black transition duration-300 delay-100 ease-in-out z-30 ${
        isMenuOpen
          ? "opacity-100 scale-100 translate-y-[0%]"
          : "opacity-0 scale-95 translate-y-[20%] pointer-events-none"
      }`}
    >
      <div className="w-full px-[12px] pt-[8px] pb-[16px] bg-black z-50">
        <div className="w-full grid grid-cols-5 h-[48px] items-center">
          {NavBarItems.map((item, index) => {
            const IconComponent = item.icon;

            return (
              <div
                className={`flex flex-col items-center gap-[2px] select-none transition-transform duration-300 ${"scale-100"} cursor-pointer`}
                key={index}
                onClick={() => {
                  onHandleNavigate(item, index);
                }}
              >
                <IconComponent
                  className={`transition-colors duration-300 ${
                    index === selectPageIndex
                      ? "text-primary"
                      : "text-secondary-300"
                  }`}
                />

                <h3
                  className={`text-center font-primary text-[10px] not-italic font-normal leading-[120%] transition-colors duration-300 ${
                    index === selectPageIndex
                      ? "text-primary"
                      : "text-secondary-500"
                  }`}
                >
                  {item.name}
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
