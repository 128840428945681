import { useEffect, useRef } from "react";

interface PopOverProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  items: { itemName: string; itemDescription: string }[];
  itemAction: (selectedItem: string) => void;
  className?: string;
  itemNameColor?: string;
  itemDescriptionColor?: string;
  children?: React.ReactNode;
}

const PopOver = ({
  isOpen,
  setIsOpen,
  title,
  items,
  itemAction,
  children,
  className,
  itemNameColor,
  itemDescriptionColor,
}: PopOverProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (itemName: string) => {
    itemAction(itemName);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // Add touchstart event for mobile

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside); // Clean up the touchstart event
    };
  }, [setIsOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      {children}
      <div
        className={`absolute left-0 flex w-[124px] p-[8px] flex-col items-start gap-[14px] rounded-[8px] bg-base-white shadow-md z-10 transition-opacity duration-200 ${className} ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`w-full h-6 text-[12px] ${itemDescriptionColor} font-primary not-italic font-normal border-b-[0.8px] border-custom-grey-d9`}
        >
          {title}
        </div>
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex p-[4px] w-full items-center justify-between hover:bg-custom-deepCharcoal rounded-[6px] cursor-pointer select-none`}
            onClick={() => handleItemClick(item.itemName)}
          >
            <p
              className={`${itemNameColor} font-primary text-[12px] not-italic font-medium leading-[120%]`}
            >
              {item.itemName}
            </p>

            <p
              className={`${itemDescriptionColor} font-primary text-[12px] not-italic font-normal leading-[120%]`}
            >
              {item.itemDescription}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopOver;
