import IconButton from "../Common/IconButton";
import SearchIcon from "../icons/SearchIcon";

const SearchBar = () => {
  return (
    <div className="w-full px-[12.5px]">
      <div className="flex items-center h-[48px] p-[10px] w-full rounded-[8px] border-[0.4px] border-solid border-base-black bg-custom-deepCharcoal10">
        <h4 className="text-base-black font-primary text-m not-italic font-medium leading-[120%]">
          Search
        </h4>
        <input
          type="text"
          className="bg-transparent px-[8px] font-primary text-sm not-italic font-medium leading-[120%] flex-grow-[1] h-[24px] outline-none"
        />

        <IconButton
          onClick={() => {}}
          size={16}
          padding={0}
          className="hover:bg-transparent"
        >
          <SearchIcon className={"text-base-black"} />
        </IconButton>
      </div>
    </div>
  );
};

export default SearchBar;
