import { useState } from "react";

import { competitions } from "../../data/Home";

import CompetitionSection from "./CompetitionSection";
import EditFavoritePopup from "./EditFavoritePopup";
import FavoriteSection from "./FavoriteSection";
import ProductionReportSection from "./ProductionReportSection";
import TrendingSection from "./TrendingSection";

const Home = () => {
  const [isEditFavoriteOpen, setIsEditFavoriteOpen] = useState(false);

  const openFavoriteEdit = () => {
    setIsEditFavoriteOpen(true);
  };

  const closeFavoriteEdit = () => {
    setIsEditFavoriteOpen(false);
  };

  return (
    <>
      <FavoriteSection openPopup={openFavoriteEdit} />
      <CompetitionSection
        competitionName="Competitions"
        competitions={competitions}
      />
      <TrendingSection />
      <ProductionReportSection reportName={"Production Report"} />
      <ProductionReportSection reportName={"Report #2"} />

      <EditFavoritePopup
        isPopupOpen={isEditFavoriteOpen}
        closePopup={closeFavoriteEdit}
      />
    </>
  );
};

export default Home;
