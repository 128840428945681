import { useEffect, useRef, useState } from "react";
import ArrowDown from "../icons/ArrowDown";

interface DropDownProps {
  options: string[]; // List of options to display in the dropdown
  selectedOption?: string; // Currently selected option
  onOptionSelect: (option: string) => void; // Callback when an option is selected
  label?: string; // Default label when no option is selected
  className?: string; // Custom class name
  buttonTextColor?: string; // Text color for the button
  buttonBgColor?: string; // Background color for the button
  buttonBorderColor?: string; // Border color for the button
  buttonWidth?: string; // Custom width for the button
  buttonHeight?: string; // Custom height for the button
  dropdownBg?: string;
  dropdownText?: string;
  dropdownOffset?: string; // Custom offset for dropdown
  buttonTextSize?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  selectedOption,
  onOptionSelect,
  label = "Select",
  className = "",
  buttonTextColor = "text-base-white", // Default text color
  buttonBgColor = "bg-primary", // Default background color
  buttonBorderColor = "border-none", // Default no border
  buttonWidth = "w-[124px]", // Default width
  buttonHeight = "h-[28px]", // Default height
  dropdownBg = "bg-custom-dropdownBg",
  dropdownText = "bg-custom-dropdownText",
  dropdownOffset = "top-[32px]",
  buttonTextSize = "text-sm",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Handle option click
  const handleOptionClick = (option: string) => {
    onOptionSelect(option);
    setIsOpen(false); // Close dropdown after selection
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        className={`relative group transition-all duration-200 focus:overflow-visible p-2 overflow-hidden flex ${buttonWidth} ${buttonHeight} px-[10px] py-[7px] justify-between items-center rounded-[8px]  ${buttonTextColor} ${buttonBgColor} ${buttonBorderColor} ${className}`}
        onClick={handleToggleDropdown}
      >
        <span
          className={`font-primary text-sm not-italic font-normal ${buttonTextSize}`}
        >
          {selectedOption || label}
        </span>
        <ArrowDown className={buttonTextColor} />
      </button>

      <div
        className={`absolute left-0 flex w-[124px] max-h-[200px] overflow-auto p-s flex-col items-start gap-[2px] rounded-[8px] bg-base-white shadow-md z-10 transition-opacity duration-200 ${dropdownBg} ${buttonWidth} ${dropdownOffset} ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        {options.map((option, index) => (
          <span
            key={index}
            className={`flex p-[4px] items-center self-stretch active:bg-custom-deepCharcoal active:text-primaryText rounded-[6px] cursor-pointer ${dropdownText}`}
            onClick={() => handleOptionClick(option)}
          >
            <p className="font-primary text-sm not-italic font-normal leading-[120%] select-none">
              {option}
            </p>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DropDown;
