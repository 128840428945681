import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./styles/App.css";

import ProtectedRoute from "./components/Common/ProtectedRoute";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import MainLayout from "./pages/MainLayout";
import ReportPage from "./pages/Report";

import { L_STORAGE_THEME } from "./const/localstorage";
import { switchTheme } from "./utils/theme";
import CompetitionPage from "./pages/Competition";

const App: React.FC = () => {
  useEffect(() => {
    const loadTheme = () => {
      try {
        const savedTheme = localStorage.getItem(L_STORAGE_THEME);
        switchTheme(savedTheme || "default"); // Use a specific default theme
      } catch (error) {
        console.error("Failed to load theme from localStorage", error);
      }
    };

    loadTheme();
  }, []);

  const routes = [
    { path: "/login", element: <LoginPage /> },
    { path: "/", element: <HomePage /> },
    { path: "/curator", element: <HomePage /> },
    { path: "/report", element: <ReportPage /> },
    { path: "/competitions", element: <CompetitionPage /> },
  ];

  return (
    <Router>
      <MainLayout>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                path !== "/login" ? (
                  <ProtectedRoute>{element}</ProtectedRoute>
                ) : (
                  element
                )
              }
            />
          ))}
        </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;
