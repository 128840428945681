import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchReportList } from "../../redux/slices/reportSlice";

import Favorites from "./Favorites";
import ReportLists from "./ReportLists";
import SearchBar from "./SearchBar";

interface SideMenuProps {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

const SideMenu = ({ isMenuOpen, closeMenu }: SideMenuProps) => {
  const dispatch = useAppDispatch();
  const { reports } = useAppSelector((state) => state.report);

  // Fetch the report list when the component mounts
  useEffect(() => {
    dispatch(fetchReportList());
  }, [dispatch]);

  return (
    <div
      className={`fixed top-[52px] w-full h-full bg-black transition duration-300 ease-in-out z-30 ${
        isMenuOpen
          ? "opacity-100 scale-100 translate-x-[0%]"
          : "opacity-0 scale-95 translate-x-[-20%] pointer-events-none"
      }`}
      style={{ height: "calc(100vh - 52px)" }}
    >
      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 bg-custom-grey-dd overflow-y-scroll pt-[12px] pb-[72px]">
        <SearchBar />

        <Favorites />

        <ReportLists reports={reports} closeMenu={closeMenu} />
      </div>
    </div>
  );
};

export default SideMenu;
