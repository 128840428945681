import { IPhoto } from "./../../interfaces/photo";
// redux/slices/reportSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFilterList } from "../../interfaces/filterList";
import { IReport } from "../../interfaces/report";
import { IReportList } from "../../interfaces/reportList";

import { ReportDisplayType } from "../../const/enums/ReportDisplayType";
import { SelectedDateType } from "../../const/enums/SelectedDateType";
import { ReportDataInfo } from "../../interfaces/reportDataInfo";
import {
  getFilteredReportData,
  getFilteredReportFilter,
  getPhotos,
  getReportDataInfo,
  getReportFilters,
  getReportList,
  getTableauJwtToken,
} from "../../services/reportService";
import { formatDate, getLastWeek } from "../../utils/date";
import {
  groupReportsByCategory,
  parseFilterData,
  parsePhotos,
  parseReportDataInfo,
  parseReports,
} from "../helpers/report";

export interface ReportState {
  reports: IReportList[];
  loading: boolean;
  error: string | null;
  currentReport: IReport | null;
  tableauJwtToken: string;

  reportFilters: IFilterList;
  selectedFilters: {
    [key: string]: string | string[];
  };

  selectedDateRange: [string, string];
  selectedDateType: SelectedDateType;
  appliedDateType: SelectedDateType;

  reportDataInfo: ReportDataInfo;
  isLoadingReportDataInfo: boolean;

  reportData: { name: string; data: any[] };
  isLoadingReportData: boolean;

  isLoadingFilters: boolean;

  photos: IPhoto[];
}

// Initial state with proper types
const initialState: ReportState = {
  reports: [],
  loading: false,
  error: null,
  currentReport: null,
  tableauJwtToken: "",

  selectedFilters: {},
  reportFilters: {},

  selectedDateRange: [
    formatDate(getLastWeek().from),
    formatDate(getLastWeek().to),
  ],

  selectedDateType: SelectedDateType.LastWeek,
  appliedDateType: SelectedDateType.LastWeek,

  reportData: { name: "", data: [] },
  isLoadingReportData: false,

  reportDataInfo: {
    name: "",
    displayType: ReportDisplayType.Chart,
    filterDefaultGroupBy: "",
    filterHierarchy: [],
    filterAdditional: [],
    filterGroupFields: {},
    tableFieldNames: [],
    tableFields: [],
  },
  isLoadingReportDataInfo: false,
  isLoadingFilters: false,

  photos: [],
};

// Async thunk for fetching production reports
export const fetchReportList = createAsyncThunk(
  "report/fetchReportList",
  async () => {
    const response = await getReportList();
    return response;
  }
);

// Async thunk for fetching production reports
export const fetchTableauJwtToken = createAsyncThunk(
  "report/fetchTableauJwtToken",
  async () => {
    const response = await getTableauJwtToken();
    return response;
  }
);

export const fetchReportFilterList = createAsyncThunk(
  "report/fetchReportFilterList",
  async () => {
    const response = await getReportFilters();
    return response;
  }
);

export const fetchReportDataInfo = createAsyncThunk(
  "report/fetchReportDataInfo",
  async ({ reportName }: { reportName: string }) => {
    const response = await getReportDataInfo(reportName);
    return response;
  }
);

export const fetchReportData = createAsyncThunk(
  "report/fetchReportData",
  async ({ reportName, filters }: { reportName: string; filters: any }) => {
    const response = await getFilteredReportData(reportName, filters);
    return response;
  }
);

export const fetchFilteredReportFilter = createAsyncThunk(
  "report/fetchFilteredReportFilter",
  async ({ reportName, filters }: { reportName: string; filters: any }) => {
    const response = await getFilteredReportFilter(reportName, filters);
    return response;
  }
);

export const fetchPhotos = createAsyncThunk("report/fetchPhotos", async () => {
  const response = await getPhotos();
  return response;
});

// Slice for production state
const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setCurrentReport: (state, { payload }) => {
      state.currentReport = payload;
    },

    updateSelectedDateRange: (state, { payload }) => {
      state.selectedDateRange = payload;
    },

    updatedSelectedDateType: (state, { payload }) => {
      state.selectedDateType = payload;
    },

    updatedAppliedDateType: (state, { payload }) => {
      state.appliedDateType = payload;
    },

    // Action to update the selected filter
    updateReportFilter: (
      state,
      action: PayloadAction<{
        filterName: string;
        selectedOption: string | string[];
      }>
    ) => {
      const { filterName, selectedOption } = action.payload;
      state.selectedFilters[filterName] = selectedOption;
    },

    clearFilter: (state) => {
      state.selectedFilters = {
        Date: state.selectedFilters["Date"],
      };
      state.selectedDateType = state.appliedDateType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportList.fulfilled, (state, action) => {
        state.loading = false;
        const reports = parseReports(action.payload);
        const reportList = groupReportsByCategory(reports);
        state.reports = reportList;
      })
      .addCase(fetchReportList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to load reports list";
      })
      .addCase(fetchTableauJwtToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTableauJwtToken.fulfilled, (state, action) => {
        state.loading = false;
        state.tableauJwtToken = action.payload.token;
      })
      .addCase(fetchTableauJwtToken.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch tableau jwt token";
      })
      .addCase(fetchReportFilterList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportFilterList.fulfilled, (state, action) => {
        state.loading = false;
        state.reportFilters = parseFilterData(action.payload);
      })
      .addCase(fetchReportFilterList.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchReportData.pending, (state) => {
        state.isLoadingReportData = true;
        state.error = null;
      })
      .addCase(fetchReportData.fulfilled, (state, action) => {
        state.isLoadingReportData = false;
        state.reportData = action.payload;
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        state.isLoadingReportData = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchReportDataInfo.pending, (state) => {
        state.isLoadingReportDataInfo = true;
        state.error = null;
      })
      .addCase(fetchReportDataInfo.fulfilled, (state, action) => {
        state.isLoadingReportDataInfo = false;
        if (Object.keys(action.payload).length === 0) return;

        const reportDataInfo: ReportDataInfo = parseReportDataInfo(
          action.payload
        );
        state.reportDataInfo = reportDataInfo;
      })
      .addCase(fetchReportDataInfo.rejected, (state, action) => {
        state.isLoadingReportDataInfo = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchFilteredReportFilter.pending, (state) => {
        state.isLoadingFilters = true;
        state.error = null;
      })
      .addCase(fetchFilteredReportFilter.fulfilled, (state, { payload }) => {
        state.isLoadingFilters = false;
        state.reportFilters = {
          ...state.reportFilters,
          Division: payload["Division"] ?? state.reportFilters["Division"],
          Region: payload["Region"] ?? state.reportFilters["Region"],
          Team: payload["Team"] ?? state.reportFilters["Team"],
          Rep: payload["Rep"] ?? state.reportFilters["Rep"],
        };
      })
      .addCase(fetchFilteredReportFilter.rejected, (state, action) => {
        state.isLoadingFilters = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchPhotos.pending, (state) => {})
      .addCase(fetchPhotos.fulfilled, (state, { payload }) => {
        state.photos = parsePhotos(payload);
      })
      .addCase(fetchPhotos.rejected, (state, { error }) => {});
  },
});

export const {
  setCurrentReport,
  updateReportFilter,
  updateSelectedDateRange,
  updatedAppliedDateType,
  updatedSelectedDateType,
  clearFilter,
} = reportSlice.actions;

export default reportSlice.reducer;
