import Backdrop from "../Common/Backdrop";
import IconButton from "../Common/IconButton";
import PlayIcon from "../icons/PlayIcon";

interface TutorialVideoProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

const TutorialVideo = ({ isPopupOpen, closePopup }: TutorialVideoProps) => {
  return (
    <Backdrop
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-black40"
      contentClassName="absolute left-[50%] translate-x-[-50%] translate-y-[-50%] top-[50%] w-[90%] h-[50vw] rounded-[8px] border-[1px] border-solid border-custom-white-80 bg-custom-darkTeal flex items-center justify-center"
    >
      <IconButton
        size={36}
        className="bg-base-white hover:bg-white shadow-borderShadow"
        onClick={() => {}}
      >
        <PlayIcon className="text-base-black w-[24px] h-[24px] active:text-primary" />
      </IconButton>
    </Backdrop>
  );
};

export default TutorialVideo;
