import { competitions } from "../../data/Home";
import Button from "../Common/Button";
import CompetitionSection from "../Home/CompetitionSection";
import TrophyIcon from "../icons/TrophyIcon";

const Competition = () => {
  return (
    <>
      <CompetitionSection
        competitionName="Sales Competitions"
        competitions={competitions}
      />
      <CompetitionSection
        competitionName="Dinner Goals"
        competitions={competitions}
      />
      <CompetitionSection
        competitionName="Incentives"
        competitions={competitions}
      />
      <div>
        <Button
          bgColor="bg-base-white"
          activeColor="active:bg-secondary-400"
          className="flex !h-[40px] px-[10px] py-[6px] items-center gap-[10px] rounded-[8px] border-[1px] border-solid border-neutral-200"
          onClick={() => {}}
        >
          <span className="text-base-black font-primary text-sm not-italic font-semibold leading-[120%]">
            Prize Locker
          </span>
          <TrophyIcon className="text-base-black" />
        </Button>
      </div>
    </>
  );
};

export default Competition;
