import { useState } from "react";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";

import trashIcon from "../../assets/icons/trash-01.svg";
import CheckIcon from "../icons/CheckIcon";
import CloseIcon from "../icons/CloseIcon";

import { favoriteReps } from "../../data/Home";
import { FavoriteReps } from "../../types/FavoriteReps";

interface EditFavoritePopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

interface FavoriteItemProps {
  favorite: FavoriteReps;
  isLast: boolean;
}

const FavoriteItem = ({ favorite, isLast }: FavoriteItemProps) => {
  const [checked, setChecked] = useState(Math.random() > 0.5);

  return (
    <div
      className={`flex px-[8px] py-[12px] gap-[12px] items-center ${
        !isLast && "border-b-[1px] border-solid border-neutral-900"
      }`}
    >
      <div
        className={`border-[1.375px] rounded-[4px] border-solid  bg-neutral-1000 w-[14px] h-[14px] ${
          checked ? "border-primary" : "border-custom-white-80"
        }`}
        onClick={() => {
          setChecked((check) => !check);
        }}
      >
        {checked && <CheckIcon className="text-primary w-3 h-3" />}
      </div>

      <img
        src={favorite.profileAvatar}
        alt="Profile Img"
        srcSet=""
        className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] flex-shrink-0 rounded-[48px] border-[1px] border-solid border-neutral-700 8dp-penumbra"
      />

      <h2 className="flex-grow-[1] text-white font-primary text-base not-italic font-semibold leading-[120%]">
        {favorite.profileName}
      </h2>

      <IconButton icon={trashIcon} size={16} onClick={() => {}}></IconButton>
    </div>
  );
};

const EditFavoritePopup = ({
  isPopupOpen,
  closePopup,
}: EditFavoritePopupProps) => {
  return (
    <div>
      <MobilePopup
        backBg="bg-black40"
        popupBg="bg-custom-deepCharcoal"
        popupBorder="ring-secondary-900"
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <div className="flex flex-col gap-m">
          <div className="flex items-center justify-between py-[16px] px-[19px] ">
            <div>
              <h2 className="text-textPrimary font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Edit Favorites
              </h2>

              <h2 className="text-textPrimary font-primary opacity-80 text-[11px] not-italic font-medium leading-[120%]">
                Add selected to homescreen
              </h2>
            </div>

            <IconButton onClick={closePopup}>
              <CloseIcon className="text-primaryText" />
            </IconButton>

            <div className="w-[1px] h-[21px] mt-[12px] bg-neutral-900 fixed left-[50%] top-0"></div>
          </div>

          <div className="flex flex-col gap-[8px] px-[12px] min-h-[500px] max-h-[70vh] overflow-y-auto">
            {favoriteReps.map((player, index) => {
              return (
                <FavoriteItem
                  favorite={player}
                  isLast={index === favoriteReps.length - 1}
                  key={index}
                ></FavoriteItem>
              );
            })}
          </div>

          <div className="flex justify-center items-center px-[12px] py-[16px] gap-[16px] border-t-[1px] border-solid border-t-neutral-800">
            <Button
              bgColor="bg-base-white"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
            >
              <span className="font-primary text-base-black text-sm not-italic font-semibold leading-[120%]">
                Cancel
              </span>
            </Button>

            <Button
              activeColor="active:bg-primary-400"
              className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
            >
              <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </MobilePopup>
    </div>
  );
};

export default EditFavoritePopup;
