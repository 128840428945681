import { Competition } from "../../types/Competition";

interface CompetitionSectionProps {
  competitionName?: string;
  competitions?: Competition[];
}

const CompetitionSection = ({
  competitionName = "Competitions",
  competitions = [],
}: CompetitionSectionProps) => {
  return (
    <div className="flex flex-col items-start gap-[12px]">
      <div className="flex justify-between items-center gap-[10px] self-stretch">
        <h2 className="text-primaryText text-lg not-italic font-semibold leading-[120%]">
          {competitionName}
        </h2>

        <button className="flex justify-center items-center px-m py-[6px]">
          <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
            View All
          </span>
        </button>
      </div>

      <div className="flex items-center overflow-x-auto w-full">
        <div className="flex gap-sm items-center">
          {competitions.map((competition, index) => {
            return (
              <div
                className="flex flex-col items-center gap-[16px] rounded-[4px] bg-item-dark-gradient p-[12px] h-[154px] max-w-[124px]"
                key={index}
              >
                <img
                  src={competition.competitionIcon}
                  alt={competition.competitionName}
                  srcSet=""
                  className="w-[80px] h-[80px] min-w-[80px] min-h-[80px] flex-shrink-0 rounded-[64px] shadow-pen-umbra-1 bg-gradient-1"
                />

                <p className="text-base-white text-center font-primary text-sm not-italic font-semibold leading-[120%] min-w-[100px]">
                  {competition.competitionName}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CompetitionSection;
